import { React } from "react"
import { Navbar, Nav, Image } from "react-bootstrap"
import "./../css/custom.css"
import logo from '../images/logoandicon/logo.png'

const NavitationBar = () => {
  return (
    <div>
      <Navbar className="navbar-css" bg="light" expand="lg">
        <Navbar.Brand className="navbar-logo navbar-color" href="#home"> <Image className=" mx-auto logo-styling" src={logo} key='logo' fluid /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto mx-auto">
            <Nav.Link className="px-5 navbar-elements" href="/">
              Home
            </Nav.Link>
            <Nav.Link className="px-5 navbar-elements" href="#services">
              Services
            </Nav.Link>
            <Nav.Link className="px-5 navbar-elements" href="#contacts">
              Contacts
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}

export default NavitationBar
