import { React } from "react";
import { Table } from "react-bootstrap";
import './../css/custom.css'

const TableContaier = (props) => {
  const tableHeads = props.tableHeads;
  const tableData = props.tableData;

  const getTableHead = () => {
    return tableHeads.map((thead, i) => {
      return <th className = "table-styling-top" key={i}>{thead}</th>;
    });
  };

  const getTableData = () => {
    return tableData.map((tData, i) => {
      return (
        <tr key={i}>
          <td>{tData.column1}</td>
          <td>{tData.column2}</td>
          <td>{tData.column3}</td>
        </tr>
      );
    });
  };

  return (
    <Table striped hover className = "table-styling" responsive="sm" key="table-1">
      <thead className = "table-styling-bottom" ><tr>{getTableHead()}</tr></thead>
      <tbody className = "table-styling-bottom">{getTableData()}</tbody>
    </Table>
  );
};

export default TableContaier;
