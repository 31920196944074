import { React } from "react";
import 'bootstrap/dist/css/bootstrap.min.css'
import './css/custom.css'

import { Container, Row, Col, Image } from "react-bootstrap";

import NavigationBar from './components/NavigationBar'
import FluidBanner from './components/FluidBanner'
import TableContainer from './components/TableContaier';
import CarouselContainer from './components/CarouselContainer'
import ReviewContainer from './components/ReviewContainer'
import TextContainer from './components/TextContainer'
import Footer from './components/FooterContainer'

import fluidImage1 from './images/banner/banner_one_color.png'
import fluidImage2 from './images/banner/banner_two_color.png'

import logo from './images/logoandicon/logo.png'

function App() {
  const images = [fluidImage2, fluidImage1]
  const logoasset = [logo]
  const tableHeads = ["Day", "Opening", "Closing"];
  const tableData = [
    { column1: "Monday", column2: "11:00 AM", column3: "8:00 PM" },
    { column1: "Tuesday", column2: "11:00 AM", column3: "8:00 PM" },
    { column1: "Wednesday", column2: "11:00 AM", column3: "8:00 PM" },
    { column1: "Thrusday", column2: "11:00 AM", column3: "8:00 PM" },
    { column1: "Friday", column2: "10:00 AM", column3: "8:00 PM" },
    { column1: "Saturday", column2: "10:00 AM", column3: "8:00 PM" },
    { column1: "Sunday", column2: "11:00 AM", column3: "6:00 PM" },
    { column1: "Holidays", column2: "Closed", column3: "Closed" },
  ];

  const priceTableHead = ["Services", "", ""];
  const priceTableData = [
    { column1: "Eyebrow Threading - Women/Men", column2: "", column3: "" },
    { column1: "Eyebrow Tinting", column2: "", column3: "" },
    { column1: "Upper Lip", column2: "", column3: "" },
    { column1: "Chin", column2: "", column3: "" },
    { column1: "Sideburn - Each", column2: "", column3: "" },
    { column1: "Forehead", column2: "", column3: "" },
    { column1: "Neck", column2: "", column3: "" },
    { column1: "Full Face (Including Eyebrows)", column2: "", column3: "" },
  ];


  const fluidTableContainers = () => {
    return (
      <Container>
        <Row>
          <Col sm>{<TableContainer tableHeads={priceTableHead} tableData={priceTableData} key='price' />}</Col>
          <Col sm>{<TableContainer tableHeads={tableHeads} tableData={tableData} key='hours' />}</Col>
        </Row>
      </Container>
    )
  }

  const fluidServicesContactsContainers = () => {
    return (
      <Container>
        <Row className="services-contacts-format">
          <Col sm>{<CarouselContainer />}</Col>
          <Col sm>{<ReviewContainer />}</Col>
        </Row>
      </Container>
    )
  }

  return (
    <div>
      <NavigationBar />
      <div className="fluid-image-position">
        <FluidBanner images={images} />
      </div>
      <div className="introduction-spacing">
        <TextContainer />
      </div>
      <div id="services" className="table-outer-styling">
        <h1 className="introduction-title">- Services & Hours -  </h1>
        {fluidTableContainers()}
      </div>
      <h1 className="introduction-title">- Testimonials -  </h1>
      <div >
        {fluidServicesContactsContainers()}
      </div>
      <div id="contacts" className="footer-background-overall">
        <Footer />
        <h5 className="copyright">Copyright © 2024 Anujaya Corp.</h5>
        <h5 className="copyright">Website created by Coveon Inc.</h5>
      </div>

    </div>
  );
}

export default App;
