import { React } from "react"
import { Col, Container, Row, Image } from "react-bootstrap"
import "./../css/custom.css"

const FluidBanner = ({ images }) => {
  const columnImage = images.map((image, index) => {
    return (
      <Col sm key={index}>
        <Image className="image-banner" src={image} key={index} fluid />
      </Col>
    )
  })
  return (
    <Container>
      <Row>{columnImage}</Row>
    </Container>
  )
}

export default FluidBanner
