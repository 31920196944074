import { React } from "react";
import { Row, Col, Container } from "react-bootstrap";
import '../css/custom.css'

const TextContainer = () => {
  return (
    <Container>
      <Row className="justify-content-md-center introduction-block">
        <Col>
          <h1 className="introduction-title">Empower. Yourself. </h1>
          <p className="introduction-text">
            Denver Threading is committed to providing its customer base with
            the utmost care and quality of services. We are here to help you
            empower yourself, Beauty comes at a cost, but this shouldn't be an
            obstacle. Thousands of satisfied customers and an ever growing loyal
            customer base can vouch for the commitment that we have for your
            beauty and what you stand for. Let us help you Empower Yourself one
            step at a time.
          </p>
          <p className="introduction-text-bottom">
            We specialize in Threading & Design. This includes Eyebrow, Chin,
            Full-face alongside several other services. We are committed to
            providing high quality and professional services at the fraction of
            a price of our competitors. With over 7 years of operation, time and
            time again you can ensure that your needs will be met.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default TextContainer;
