import { React } from "react"
import { Col, Container, Row, Image } from "react-bootstrap"

import reviewImage1 from "./../images/review/review-1.jpg"
import reviewImage2 from "./../images/review/review-2.jpg"

const ReviewContainer = () => {
  return (
    <Container>
      <Row>
        <Col sm={0.2}>
          <Image width="75" height="75" src={reviewImage1} roundedCircle />
        </Col>
        <Col sm>
          <p>
            "Best experience I had doing my eyebrows, very friendly and easy going. Fast and reasonable price, would
            consider for anyone interested in doing their eyebrows. Overall, I am very satisfied with my visit and will
            be coming here again in the future!"
          </p>
          <p className="services-contacts-name"> - Upama M.</p>
        </Col>
      </Row>

      <Row>
        <Col sm={0.2}>
          <Image width="75" height="75" src={reviewImage2} roundedCircle />
        </Col>
        <Col sm>
          <p>
            "Service was fast and friendly! Very Satisfied with the work and Anu. Would recommend to anyone interested
            in getting their eyebrows done in an efficient and cost-friendly manner! Definately will come back in the
            future!"
          </p>
          <p className="services-contacts-name"> - Carrie L.</p>
        </Col>
      </Row>
    </Container>
  )
}

export default ReviewContainer
