import { React } from "react"
import { Carousel } from "react-bootstrap"
import image1 from "../images/carousel/carousel-one.png"
import image2 from "../images/carousel/carousel-two.png"
import image3 from "../images/carousel/carousel-three.png"
import image4 from "../images/carousel/carousel-four.png"

const intervalTime = 3000
const CarouselContainer = (props) => {
  const carouselContainerData = [
    {
      imageText: "",
      imageSrc: image1,
      imageDescription: "",
    },

    {
      imageText: "",
      imageSrc: image2,
      imageDescription: "",
    },
    {
      imageText: "",
      imageSrc: image3,
      imageDescription: "",
    },
    {
      imageText: "",
      imageSrc: image4,
      imageDescription: "",
    },
  ]

  const carouselData = carouselContainerData.map((carouselData, i) => {
    return (
      <Carousel.Item interval={intervalTime} key={i}>
        <img className="d-block w-100" src={carouselData.imageSrc} alt={carouselData.imageText} />
        <Carousel.Caption>
          <h3>{carouselData.imageText}</h3>
          <p>{carouselData.imageDescription}</p>
        </Carousel.Caption>
      </Carousel.Item>
    )
  })

  return <Carousel>{carouselData}</Carousel>
}

export default CarouselContainer
